class LoginModule {

    private readonly emailSelector: string = "#Email";

    public initialize() {
        this.setupEvents();
    }

    private setupEvents() {

        const $this = this;

        $("#spinner").hide();

        $(this.emailSelector).on("keydown", function(e) {

            if ($($this.emailSelector).val() !== "") {
                $("#adgangskode-row").hide();
            }

            $this.handleOnEnterClick(e);
        });

        $(this.emailSelector).on("blur", function(e) {
            $this.videre();
        });

        $("#RememberMe").on("keydown", function (e) {
            $this.handleOnEnterClick(e);
        });

        $("#videre").on("click", function (e) {
            $this.videre();
        });

        $("#prov-igen").on("click", function (e) {
            $this.videre();
        });

        $("#create-user-button").on("click", function () {
            const email = encodeURIComponent($("#Email").val() as string);
            $(this).prop("href", `/opretbruger/index?email=${email}`);
        });

        $(this.emailSelector).trigger("focus");
    }

    private handleOnEnterClick(e) {
        // tab eller enter
        if (e.which === 9 || e.which === 13) {
            e.preventDefault();
            this.videre();
        }
    }

    private static isEmail(email) {
        const regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        return regex.test(email);
    }

    private videre() {

        const emailErrorSelector = $("#email-error");

        LoginModule.handleCookie();
        emailErrorSelector.hide();
        const email = $("#Email").val();

        if (LoginModule.isEmail(email)) {
            $("#spinner").show();
            $("#videre").hide();
            $("#husk-mig-row").hide();

            const url = `/login/getlogininfo?email=${email}`;

            $.get(url, null, function (data) {
                LoginModule.handleGetLoginInfoResult(data);
            }).fail(function () {
                window.location.assign("/error/");
            });
        } else {
            emailErrorSelector.show();
        }
    }

    private static handleCookie() {
        if ($("#RememberMe").is(":checked")) {
            LoginModule.createCookie("LetdialogLogin", $("#Email").val(), 1000);
        } else {
            LoginModule.eraseCookie("LetdialogLogin");
        }
    }

    private static handleGetLoginInfoResult(data: any) {
        $("#spinner").hide();
        if (data.Email !== null) {

            if (data.Type === "Andet") {
                LoginModule.showAdgangskodeBlock();
            } else if (data.Type === "Ad") {
                const adId = data.AdId;
                window.location.href = `/login/redirectToAdLogin?adId=${adId}`;
            } else if (data.Type === "Nemid") {
                if (data.HasLetDialogAdgangskode) {
                    LoginModule.showAdgangskodeBlock();
                    $("#nem-id").show();
                } else {
                    window.location.href = "/login.ashx";
                }
            }
        }
        else {
            // Der findes ingen bruger med denne email
            $("#email-does-not-exist-error").show();
        }
    }

    private static showAdgangskodeBlock() {
        $("#adgangskode-row").show();
        $("#submit").show();
        $("#Password").trigger("focus");
        $("#glemt-adgangskode").show();
        $("#prov-igen-block").hide();
        $("#email-does-not-exist-error").hide();
    }

    private static createCookie(name, value, days) {
        let expires;

        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = `; expires=${date.toUTCString()}`;
        } else {
            expires = "";
        }
        document.cookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}${expires}; path=/`;
    }

    private static eraseCookie(name) {
        LoginModule.createCookie(name, "", -1);
    }
}

$(() => {
    const loginModule = new LoginModule();
    loginModule.initialize();
});
